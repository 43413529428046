<template>
  <div>
    <portal to="header">
      <terminal-header
        :title="$t('Guided receiving')"
        @barcode="handleBarcode"
      />
    </portal>

    <portal to="settings">
      <tiles :schema="tilesSchema" />
    </portal>

    <q-carousel
      :model-value="action"
      transition-prev="scale"
      transition-next="scale"
      swipeable
      animated
      class="terminal-min-height"
    >
      <q-carousel-slide name="scanBasket" class="q-pa-none">
        <jumbotron />
      </q-carousel-slide>

      <q-carousel-slide name="next" class="relative q-pa-none">
        <fifo-receiving-content
          :barcode="barcode"
          :queues="queues"
          :place="place"
          @block="handleBlock"
          @unblock="handleUnblock"
          @reset="handleReset"
        />
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Sorting',
  components: {
    FifoReceivingContent: defineAsyncComponent(() => import('./FifoReceivingContent.vue'))
  },
  data () {
    return {
      action: 'scanBasket',
      queues: [],
      isBlocked: false,
      barcode: null,
      place: null,
      helper: null
    }
  },
  computed: {
    tilesSchema () {
      return []
    }
  },
  mounted () {
    this.addHelper('Please, scan working place!')
      .then(helper => {
        this.helper = helper
      })
  },
  unmounted () {
    if (this.helper) {
      this.removeHelper(this.helper.id)
    }

    if (this.place) {
      this.$channels.user.publish('closePlace', this.place)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'removeHelper'
    ]),
    ...mapActions([
      'addHelper'
    ]),
    handleBlock () {
      this.isBlocked = true
    },
    handleUnblock () {
      this.isBlocked = false
    },
    handleReset () {
      this.action = 'scanBasket'
      this.queues = []
      this.isBlocked = false
      this.barcode = null

      if (this.place) {
        this.$channels.user.publish('closePlace', this.place)
      }

      this.place = null
    },
    handleBarcode (barcode) {
      if (this.action === 'scanBasket') {
        const isOpened = this.$utils.storeUtils.getLockedPlaceEvent(barcode.value)

        if (isOpened) {
          this.addErrorNotification(`Someone already work on this place!`)
          return Promise.resolve(null)
        }

        return this.$service.storagePlace.get(barcode.value)
          .then(place => {
            if (place.type !== 'employee') {
              this.addErrorNotification('You must scan employee place!')
              return
            }

            const query = {
              filter: [
                { type: 'eq', field: 'adapter', value: 'allocation' },
                { type: 'eq', field: 'warehouse', value: place._embedded.warehouse.id }
              ]
            }

            return this.$service.picking.getAll(query)
              .then(({ items }) => {
                this.place = barcode.value
                this.$channels.user.publish('openPlace', this.place, place)

                this.queues = items.map(data => {
                  return {
                    type: 'Orderadmin\\Storage\\Entity\\Picking\\Queue',
                    event: 'storage.tasks.queue.opened',
                    data
                  }
                })

                this.action = 'next'
                if (this.helper) {
                  this.removeHelper(this.helper.id)
                }
              })
          })
      }

      this.barcode = barcode
    }
  }
}
</script>
